<template>
  <div class="page-wrapper">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="content-header row">
      <div class="content-header-left col-md-9 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <div class="col-12">

            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Get Quote</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Quote Summary</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Payment</a>
                </li>
                <li class="breadcrumb-item active">Underwriting
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <hr/>
      </div>
    </div>

    <div class="row mb-3 mt-2 ml-1">
      <div class="col-md-12">
        <h3>See if you qualify </h3>
      </div>
    </div>



    <section class="quotation-section" >


      <div class="card" >
        <div class="card-content">
          <div class="card-body">
            <form method="post" action="#" v-on:submit.prevent="validateQuotationForm">
              <div class="container mt-5">

                <div class="row mb-3">
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.type.$error }">
                      Type of car
                    </label>

                    <v-select v-model.trim="$v.quotation.type.$model"   @input="updateCarBrand"    label="make" :options="car_brands"></v-select>

                    <div class="text-danger" v-if="!$v.quotation.type.required && $v.quotation.type.$dirty">Type of car is required</div>

                  </div>
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.model.$error }">
                      Type of vehicle model
                    </label>


                    <v-select v-model.trim="$v.quotation.model.$model"    label="model" :options="car_models"></v-select>

                    <div class="text-danger" v-if="!$v.quotation.model.required && $v.quotation.model.$dirty">Vehicle model is required</div>

                  </div>
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.year.$error }">
                      Year of manufacture
                    </label>

                    <v-select v-model.trim="$v.quotation.year.$model"     :options="years"></v-select>

                    <div class="text-danger" v-if="!$v.quotation.year.required && $v.quotation.year.$dirty">YoM is required</div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.estimate.$error }">
                      Value of car
                    </label>
                    <input v-model.trim="$v.quotation.estimate.$model"  class="form-control" type="number" />

                    <div class="text-danger" v-if="!$v.quotation.estimate.required && $v.quotation.estimate.$dirty">Value of car is required</div>
                  </div>

                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.registrationNo.$error }">
                      Vehicle registration Number
                    </label>
                    <input v-model.trim="$v.quotation.registrationNo.$model"  class="form-control" type="text" />
                    <div class="text-danger" v-if="!$v.quotation.registrationNo.required && $v.quotation.registrationNo.$dirty">Registration number is required</div>
                  </div>
                  <div class="col-md-4">
                    <label :class="{ 'text-danger': $v.quotation.mileage.$error }">
                      Mileage
                    </label>

                    <input v-model.trim="$v.quotation.mileage.$model"  class="form-control" type="number" />

                    <!--<v-select v-model="quotation.registrationNo"   :options="mileage_ranges"></v-select>-->

                    <div class="text-danger" v-if="!$v.quotation.mileage.required && $v.quotation.mileage.$dirty">Mileage is required</div>

                  </div>


                </div>
                <div class="row mt-3 mb-5" >
                  <div class="col-md-4 offset-md-4">
                    <button  type="submit" class="btn btn-block btn-lg quotation-button"> Get Quote </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </section>


  </div>
</template>

<script>

 import { mapActions } from "vuex";
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
 import { required} from 'vuelidate/lib/validators'


export default {
  name: 'home',
  data () {
    return {
      isLoading: false,
      fullPage: true,
      value: null,
      options: ['list', 'of', 'options'],
      car_brands:[],
      car_models:[],
      mileage:"",

      quotation:{
        type:"",
        model:"",
        year:"",
        estimate:"",
        mileage:"",
        registrationNo:""
      },
      mileage_ranges:["Less than 5,000","5,000 - 10,000","10,000+"]
    };
  },
  mounted(){
    this.getVehicleMakesData();
  },
  validations: {
    quotation:{
      type:{required},
      model:{required},
      year:{required},
      estimate:{required},
      mileage:{required},
      registrationNo:{required}
    },
  },
  computed:{
    years(){
      let carManufactureYears = [];

      for(let i = 2022; i >= 2000; i--){

        carManufactureYears.push(i);
      }


      return carManufactureYears;
    }
  },
  components: { Loading },
  methods:{

    ...mapActions(["getVehicleMakes","getVehicleModels","getQuote"]),

    getVehicleMakesData(){
          let self = this;

          this.getVehicleMakes().then((vehicles) => {
             self.car_brands = vehicles.data;

          }).catch(error => {
               console.log(error);
          })
    },

    getVehicleModelData(vehicleId){
      let self = this;

      this.isLoading = true;

      this.getVehicleModels({id: vehicleId}).then((vehicles) => {
        self.isLoading = false;
        self.car_models = vehicles.data;


        console.log(vehicles);
      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })
    },

    updateCarBrand(type){
      this.getVehicleModelData(type._id);
    },

    validateQuotationForm(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.checkQuotation()
      }
    },
    checkQuotation(){
      let self = this;

      let quoteData = {
        modelId: this.quotation.model._id,
        sumInsured:this.quotation.estimate,
        mileage:this.quotation.mileage,
        yearOfManufacture:this.quotation.year,
        vehicleRegistrationNumber:this.quotation.registrationNo
      };

      this.isLoading = true;


      this.getQuote(quoteData).then((quote) => {
        self.isLoading = false;



        self.$toast.open({
          message: quote.message,
          type: 'success',
          duration:5000,
          position:"top-right"
        });

        localStorage.setItem("quote",JSON.stringify(
                {
                  quoteId: quote.data._id,
                  modelId: quote.data.modelId,
                  traditionalPremium: quote.data.traditionalPremium,
                  sumInsured: quote.data.sumInsured,
                  make:self.quotation.type.make,
                  model:self.quotation.model.model,
                  yearOfManufacture:quote.data.yearOfManufacture,
                  vehicleRegistrationNumber:self.quotation.registrationNo,
                  distancePremium: quote.data.distancePremium
                }
                ));

        self.$router.push('/app/quote');

      }).catch(error => {
        self.isLoading = false;
        console.log(error);
      })


    },
    navigateToTermsAndConditions(){
      this.$modal.hide('pricing-preview');

      this.$router.push('/quote')
    }
  }
}
</script>


<style>
  label{
    font-family: 'Nunito Sans', sans-serif;
  }

</style>



